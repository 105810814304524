import * as api from "./defaults";
import * as aws from "../aws-exports";
// ==============================
// ========= ENVIROMENT =========
// ==============================

export const GET_PROD_OR_DEV = aws.RUNNING_ENVIRONMENT;

const isDev = String(GET_PROD_OR_DEV) === String(api.DEV);

// ===============================
// ======== APIs ANALITCS ========
// ===============================

//  Analytics salvos nos emails atendimento@...
const MAP_GOOGLE_ANALYTICS = {
  [api.PROD]: "",
  [api.DEV]: "G-EQP39SKN16",
};
export const GOOGLE_ANALYTICS = MAP_GOOGLE_ANALYTICS[GET_PROD_OR_DEV];

// Pixel - Facebook
const MAP_PIXEL_ID = {
  [api.PROD]: "",
  [api.DEV]: "1223888761375859",
};
export const PIXEL_ID = MAP_PIXEL_ID[GET_PROD_OR_DEV];

// ==============================
// ------------ APIs ------------
// ==============================

const API_URL_DEV = "https://sandbox.audiofoco.com.br";
// const API_URL_DEV_OLD = "https://j84x5f3up3.execute-api.us-east-1.amazonaws.com/maratona-dev";
const API_URL_PROD = "https://api.audiofoco.com.br";

const AUDIOFOCO_LINK_DEV = "http://dev.maratona.afinando.com.br";
const AUDIOFOCO_LINK_PROD = "https://audiofoco.com.br";
export const AUDIOFOCO_LINK = isDev ? AUDIOFOCO_LINK_DEV : AUDIOFOCO_LINK_PROD;

export const API_EINSTEIN = isDev? "https://rh-sso-hom.einstein.br/auth/realms/Brain/protocol/openid-connect/token" : "https://rhsso.einstein.br/auth/realms/Brain/protocol/openid-connect/token";
// old
// client_id: frontend
// grant_type: password
// new
// client_id: frontend
// Protocolo: OpenId Connect
// Access type: Público
// chave pública: https://rh-sso-hom.einstein.br/auth/realms/Brain

// ===============================
// ======= APIs ENVIROMENT =======
// ===============================

// API URL to use
const API_URL = isDev ? API_URL_DEV : API_URL_PROD;

// PARTNER LOGIN
export const API_PARTNER_LOGIN = API_URL + "/partner/login";

// BUY
export const API_URL_SALES_ORDER = API_URL + "/sales/process-order";

// COMPANY
export const API_COMPANY = API_URL + `/company`;
export const API_COMPANY_INFO = API_URL + `/company/info`;
export const GET_API_COMPANY = (company_uid: string) =>
  API_URL + `/company/${company_uid}`;
// CONTENT
// export const API_GAMES =API_URL + `/content/games`
// GAMES
export const API_GAMES = API_URL + `/games`;
// SCORE
export const API_SCORE = API_URL + `/score`;
// USER
export const GET_API_USER = (registry: string) => API_URL + `/user/${registry}`;
export const API_USER_BIRTHDATE = API_URL + `/user/birthdate`;
export const API_USER_LOGIN = API_URL + `/user/login`;
export const API_USER_RESEND_PASSWORD = API_URL + `/users/resend-password`;
export const UPDATE_USER_GROUP = API_URL + `/users/group`;
export const UPDATE_USER_SHOTS = API_URL + `/user/shots`;
//STATISTICS
export const GET_API_REPORT = (user_id: string) => API_URL + `/statistics/${user_id}/plays`;
export const GET_API_TESTS = (user_id: string) => API_URL + `/statistics/${user_id}/smart-tests`;
export const GET_API_DATE_IA = (user_id: string) => API_URL + `/statistics/${user_id}/date-ia`;
// MY USERS
export const API_USERS = API_URL + `/users`;
export const API_USERS_ALL = API_URL + `/users/all`;
export const API_USERS_ALL_AUDIOFOCO = API_URL + `/users/all/01`;
export const API_USERS_GROUPS_AUDIOFOCO = (company_uid: any) => API_URL + `/company/groups/`+company_uid;
export const API_USERS_POST_NEW_GROUP = API_URL + `/company/groups`;
export const API_USERS_ALL_SMART = API_URL + `/users/all/10`;
export const API_USER_SMART = API_URL + `/user/smart`;
export const API_USER_STATISTICS = API_URL + `/user/statistics/smart`;
export const API_USER_GAMES = API_URL + `/user/plays`;
export const API_USER_SKIP_PROGRESS = API_URL + `/user/skip-label`;
export const API_TESTS_POST = API_URL + `/user/score-test`;
export const GET_API_USERS_BY_SUB = (sub: string) => API_URL + `/users/${sub}`;
export const GET_API_USER_BY_REGISTRY = (sub: string) => API_URL + `/users/${sub}`;
export const API_USERS_REMOVE = API_URL + `/users/remove`;
export const API_USERS_ACTIVE = API_URL + `/user/activate`;
export const DELETE_API_USERS = (registry: string) => API_URL + `/users/delete/${registry}`;
// export const DELETE_API_USERS = API_URL + "/users/delete";
// MY PROFESSIONALS
export const API_PRO = API_URL + `/professionals`;
export const API_PRO_ALL = API_URL + `/professionals`;
export const API_PRO_ENABLE_DISABLE = API_URL + `/professionals/enable-disable`;
export const API_PRO_PERMISSIONS = API_URL + `/professionals/permissions`;
// ==============================
// ======== STATIC FILES ========
// ==============================

// http://dev.maratona.afinando.com.br/
export const DEFAULT_MODEL_EMPLOYEE_XLSX =
  "/downloads/modelo_planilha_usuario.xlsx";

export const FILE_MORE_USERS_THAN_LIMIT =
  "The file contains more users than limit";
export const MORE_USERS_THAN_YOUR_LIMIT =
  "You have reached the user limit to add to your plan";

export const SECRET_KEY =
  "679b5e7e304675f4751ffb71364524ad";